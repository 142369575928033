import {campaignsApi} from "./CampaignService";
import {request} from "./Api";

export const referenceService = {
    getMemory,
    getMemories,
    getMemoriesByType
};

async function getMemory(memoryType, slug) {
    return request(
        'GET',
        `/campaign/${campaignsApi.getSelectedCampaignId()}/memories/${memoryType}/${slug}`
    );
}

async function getMemories() {
    return request(
        'GET',
        `/campaign/${campaignsApi.getSelectedCampaignId()}/memories`
    );
}

async function getMemoriesByType(memoryType) {
    return request(
        'GET',
        `/campaign/${campaignsApi.getSelectedCampaignId()}/memories`,
        {params: {memoryType: memoryType}}
    );
}


