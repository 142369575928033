import {Button} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import React from "react";
import {useAuth0} from "@auth0/auth0-react";

function AccountNav() {

    const {isLoading, isAuthenticated, logout, loginWithRedirect} = useAuth0();

    let history = useHistory();

    if (isLoading) {
        return <Button disabled color="inherit">Loading...</Button>
    }

    const handleLogout = () => {
        logout({localOnly: true})
        history.push('/')
    }

    const handleLogin = async () => {
        console.log("Logging in...")
        await loginWithRedirect()
        console.log("Logged in!")
        history.push('/campaigns')
    }

    const handleSignup = async () => {
        await loginWithRedirect({
            screen_hint: "signup",
        })
        history.push('/campaigns')
    }

    if (isAuthenticated) {
        return (
            <React.Fragment>
                <Button color="inherit" onClick={handleLogout}>Logout</Button>
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                <Button onClick={handleSignup} variant="contained" color="secondary">Sign Up</Button>
                <Button onClick={handleLogin} color="inherit">Login</Button>
            </React.Fragment>
        )
    }
}

export default AccountNav
