const randomBlurbs = [
    "It was a dark and stormy night...",
    "The wizard searched through her bags...",
    "The wizard started casting Fireball...",
    "This might be a trap...",
    "It might be time for a long rest...",
    "Are you sure that's a good idea?",
    "The pirates jeered at us...",
    "\"Only a fool would enter my lair unprepared...\"",
    "The barbarian let out a blood-curdling howl..."
]

export function randomBlurb() {
    return randomBlurbs[Math.floor(Math.random() * randomBlurbs.length)];
}