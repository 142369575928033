import React from 'react'
import {Avatar, Chip} from "@material-ui/core";

const properties = {
    QUEST: {
        avatarLetter: "!",
        path: "/memories/quests/"
    },
    CHARACTER: {
        avatarLetter: "@",
        path: "/memories/characters/"
    },
    LOCATION: {
        avatarLetter: "#",
        path: "/memories/locations/"
    }
}

function MemoryChip(props) {
    const {id, type, name, slug} = props.memory
    const onDelete = props.onDelete || false
    const typeProperties = properties[type]

    if (onDelete) {
        return (
            <Chip label={name}
                  size={"small"}
                  avatar={<Avatar>{typeProperties.avatarLetter}</Avatar>}
                  onDelete={() => onDelete(id)}
            />
        )
    }

    return (
        <Chip label={name}
              size={"small"}
              avatar={<Avatar>{typeProperties.avatarLetter}</Avatar>}
              clickable component="a" href={typeProperties.path + slug}
        />
    )
}

export default MemoryChip
