import React from "react";
import {Link} from "react-router-dom";

function MemoryLink(props) {
    const {prefix, type, path} = props
    const {name, slug} = props

    const QUESTS_PATH = "/memories/quests/"
    const CHARACTERS_PATH = "/memories/characters/"
    const LOCATIONS_PATH = "/memories/locations/"

    const getPath = () => {
        if (path) {
            return path;
        }

        let criteria = prefix || type
        switch (criteria) {
            case "!":
            case "QUEST":
                return QUESTS_PATH
            case "@":
            case "CHARACTER":
                return CHARACTERS_PATH
            case "#":
            case "LOCATION":
                return LOCATIONS_PATH
            default:
                console.error("could not determine path for criteria " + criteria)
                // what should you do in JS?
                return "/memories/unknown/";
        }
    }

    return (
        <Link to={getPath() + slug}>{name}</Link>
    )
}


export default MemoryLink