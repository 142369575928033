import createAuth0Client from "@auth0/auth0-spa-js";

let _initOptions = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE
}
let _client

const getAuth0Client = () => {
    return new Promise(async (resolve, reject) => {
        let client
        if (!client) {
            try {
                client = await createAuth0Client(_initOptions)
                resolve(client)
            } catch (e) {
                reject(e)
            }
        }
    })
}

export const getApiToken = async (...p) => {
    if (!_client) {
        _client = await getAuth0Client()
    }
    return await _client.getTokenSilently(...p)
}
