import React from 'react'

function AccessibleEmoji(props) {

    return (
        <span role="img" aria-label={props.description}>{props.emoji}</span>
    )
}

export default AccessibleEmoji
