import React from "react";
import {Button, Card, CardActions, CardContent, makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        margin: 5
    }
});

function CampaignCard(props) {

    const classes = useStyles();
    const {campaign, onSelectCampaign} = props

    return (
        <Card className={classes.root}>
            <CardContent><h3>{campaign.name}</h3></CardContent>
            <CardActions>
                <Button size="small" onClick={() => onSelectCampaign(campaign)}>Select</Button>
            </CardActions>
        </Card>
    )
}

export default CampaignCard