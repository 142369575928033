import React from "react";

function Home() {

    return (
        <React.Fragment>
            <h1>Welcome to Dungeon Notekeeper!</h1>
            <p>Dungeon Notekeeper is currently in development and changing constantly. It is not currently recommended
                to use this tool for your notes just yet as the database is frequently reset whilst we iterate on
                different software architecture designs.</p>

            <h2>Changelog</h2>

            <h3>2021-06-23</h3>
            <h4>Web</h4>
            <ul>
                <li>Added this changelog to the home page.</li>
            </ul>
            <h4>Server</h4>
            <ul>
                <li>Migrated the underlying database to MongoDB.</li>
            </ul>
        </React.Fragment>
    )
}

export default Home
