import {request} from "./Api";

export const campaignsApi = {
    createCampaign,
    getCampaigns,
    setSelectedCampaign,
    getSelectedCampaign,
    getSelectedCampaignId,
    clearSelectedCampaign
};

function getCampaigns() {
    return request('GET', '/campaigns');
}

function createCampaign(name) {
    return request('POST', '/campaigns', {body: {name: name}})
}

function getSelectedCampaignId() {
    return getSelectedCampaign().id;
}

function setSelectedCampaign(campaign) {
    if (campaign) {
        localStorage.setItem('campaign', JSON.stringify(campaign));
    } else {
        console.error(`Did not set campaign: [${campaign}]`)
    }
}

function getSelectedCampaign() {
    return JSON.parse(localStorage.getItem('campaign'))
}


function clearSelectedCampaign() {
    localStorage.removeItem('campaign');
}
