import React from "react";
import {Route, Switch} from "react-router-dom";
import SessionNotes from "../pages/SessionNotes";
import Home from "../pages/Home";
import PrivateRoute from "./PrivateRoute";
import CampaignSelection from "../pages/CampaignSelection";
import MemoryNotes from "../pages/MemoryNotes";
import Roadmap from "../pages/Roadmap";
import {useAuth0} from "@auth0/auth0-react";
import {CircularProgress} from "@material-ui/core";
import Memories from "../pages/Memories";

function Routes() {

    const {isLoading} = useAuth0();

    if (isLoading) {
        return <CircularProgress/>
    }

    return (
        <Switch>

            <PrivateRoute path="/campaigns">
                <CampaignSelection/>
            </PrivateRoute>

            <PrivateRoute requiresCampaign path="/notes">
                <SessionNotes/>
            </PrivateRoute>

            <PrivateRoute requiresCampaign path="/memories/:memoryType/:slug">
                <MemoryNotes/>
            </PrivateRoute>

            <PrivateRoute requiresCampaign path="/memories">
                <Memories/>
            </PrivateRoute>

            <Route path="/roadmap">
                <Roadmap/>
            </Route>
            <Route path="/">
                <Home/>
            </Route>
        </Switch>
    )
}

export default Routes
