import React, {useEffect, useState} from "react";
import {List, ListItem} from "@material-ui/core";
import {useKeyPress} from "../util/useKeyPress";

function Suggester(props) {

    const {suggestions, onSuggestionSelected} = props;

    const [selectedIndex, setSelectedIndex] = useState(0)
    const [hovered, setHovered] = useState(undefined)
    const [selected, setSelected] = useState(undefined)

    const downPress = useKeyPress("ArrowDown");
    useEffect(() => {
        if (suggestions.length && downPress) {
            setSelectedIndex(prevState => prevState < suggestions.length - 1 ? prevState + 1 : prevState);
            setHovered(undefined)
        }
    }, [downPress, suggestions]);

    const upPress = useKeyPress("ArrowUp");
    useEffect(() => {
        if (suggestions.length && upPress) {
            setSelectedIndex(prevState => (prevState > 0 ? prevState - 1 : prevState));
            setHovered(undefined)
        }
    }, [upPress, suggestions]);

    const enterPress = useKeyPress("Enter");
    useEffect(() => {
        if (suggestions.length && enterPress) {
            setSelected(suggestions[selectedIndex]);
        }
    }, [selectedIndex, enterPress, suggestions]);

    useEffect(() => {
        if (selected) {
            onSuggestionSelected(selected)
            setSelected(undefined)
            setHovered(undefined)
        }
    }, [selected, onSuggestionSelected]);

    return (
        <List autoFocus>
            {props.suggestions.map(suggestion =>
                <ListItem
                    selected={hovered ? suggestion.slug === hovered.slug : suggestion.slug === suggestions[selectedIndex].slug}
                    key={suggestion.slug}
                    onMouseEnter={() => setHovered(suggestion)}
                    onMouseLeave={() => setHovered(undefined)}
                    onClick={() => setSelected(suggestion)}>
                    {suggestion.name}
                </ListItem>)
            }
        </List>)
}

export default Suggester
