import React from "react";
import NavigationBar from "./nav/NavigationBar";
import {BrowserRouter} from "react-router-dom";
import Routes from "./nav/Routes";
import {Container, CssBaseline} from "@material-ui/core";
import {Auth0Provider} from "@auth0/auth0-react";

function App() {

    const {REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_AUDIENCE} = process.env;

    return (
        <React.Fragment>
            <CssBaseline/>
            <BrowserRouter>
                <Auth0Provider
                    domain={REACT_APP_AUTH0_DOMAIN}
                    clientId={REACT_APP_AUTH0_CLIENT_ID}
                    audience={REACT_APP_AUTH0_AUDIENCE}
                    redirectUri={window.location.origin}>
                    <Container>
                        <NavigationBar/>
                        <Routes/>
                    </Container>
                </Auth0Provider>
            </BrowserRouter>
        </React.Fragment>
    )
}

export default App
