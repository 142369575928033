import React from "react";
import {List, ListItem, ListItemText} from "@material-ui/core";

function Roadmap() {

    const roadmap = [
        "Functioning Login (using Auth0?)",
        "Organize notes into sessions",
        "Show all connected memories on memory page (not just list of notes)",
        "Scroll to note",
        "Note editing",
        "Memory description editing",
        "'Item' memories"
    ]

    return (
        <React.Fragment>
            <h1>Roadmap</h1>

            <List>
                {
                    roadmap.map(value =>
                        <ListItem>
                            <ListItemText>{value}</ListItemText>
                        </ListItem>
                    )
                }
            </List>
        </React.Fragment>
    )
}

export default Roadmap
