import React, {useState} from 'react'
import {Button, IconButton} from "@material-ui/core";
import {notesService} from "../api/NotesService";
import CancelIcon from '@material-ui/icons/Cancel';
import MemoryChip from "./MemoryChip";

function Note(props) {

    const {note, onNewReference} = props;

    const [memories, setNoteMemories] = useState(note.memories)
    const [noteText, setNoteText] = useState(note.text)
    const [showCreateButtons, setShowCreateButtons] = useState(false);
    const [selectedText, setSelectedText] = useState('');

    const handleSelectedText = () => {
        let selection = window.getSelection();
        const text = selection.toString()
        if (text.length >= 3) {
            setSelectedText(text)
            setShowCreateButtons(true)
        } else {
            cancelSelection()
        }
    }

    const cancelSelection = () => {
        setSelectedText('')
        setShowCreateButtons(false)
    }

    const createCharacter = () => {
        notesService.createReference(note.id, selectedText, 'CHARACTER')
            .then(note => {
                setNoteText(note.text)
                setNoteMemories(note.memories)
                setSelectedText('')
                setShowCreateButtons(false)
                onNewReference()
            })
    }

    const createQuest = () => {
        notesService.createReference(note.id, selectedText, 'QUEST')
            .then(note => {
                setNoteText(note.text)
                setNoteMemories(note.memories)
                setSelectedText('')
                setShowCreateButtons(false)
                onNewReference()
            })
    }

    const createLocation = () => {
        notesService.createReference(note.id, selectedText, 'LOCATION')
            .then(note => {
                setNoteText(note.text)
                setNoteMemories(note.memories)
                setSelectedText('')
                setShowCreateButtons(false)
                onNewReference()
            })
    }

    let createButtons = <React.Fragment/>
    if (showCreateButtons) {
        createButtons =
            <React.Fragment>
                <p>Remember <i>"<b>{selectedText}</b>"</i> as a:</p>
                <Button size="small" variant="outlined" color="secondary" onClick={createCharacter}>Character</Button>
                <Button size="small" variant="outlined" color="secondary" onClick={createQuest}>Quest</Button>
                <Button size="small" variant="outlined" color="secondary" onClick={createLocation}>Location</Button>
                <IconButton size="small" variant="outlined" color="secondary" aria-label="cancel"
                            onClick={() => cancelSelection()}>
                    <CancelIcon/>
                </IconButton>
            </React.Fragment>
    }

    let boldedNoteText = noteText
    memories.forEach(m => boldedNoteText = boldedNoteText.replace(RegExp(m.name, 'gi'), `<strong>${m.name}</strong>`))

    return (
        <React.Fragment>
            <div onMouseUp={handleSelectedText}
                 onContextMenu={handleSelectedText}
            >
                <span dangerouslySetInnerHTML={{__html: boldedNoteText}}/>
            </div>
            {
                memories.map(memory =>
                    <MemoryChip key={memory.id} memory={memory}/>
                )
            }
            {createButtons}
        </React.Fragment>
    );
}

export default Note
