import React, {useEffect, useRef, useState} from 'react'
import {notesService} from "../api/NotesService"
import {IconButton, TextField} from "@material-ui/core"
import SendIcon from '@material-ui/icons/Send'
import {randomBlurb} from "../util/randomBlurb"
import Suggester from "./Suggester";
import MemoryChip from "./MemoryChip";

function NoteInput(props) {

    const scrollRef = useRef(null)
    const [noteText, setNoteText] = useState("")

    const [showSuggester, setShowSuggester] = useState(false)
    const [suggestions, setSuggestions] = useState([])
    const [memories, setMemories] = useState([])

    const scrollToBottom = () => {
        scrollRef.current?.scrollIntoView({behavior: "smooth"})
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if (!noteText) {
            return
        }
        notesService.createNote(noteText, memories)
            .then((note) => {
                props.onNewNote(note)
            })
            .then(() => {
                setNoteText('')
                setMemories([])
            })
    }

    useEffect(() => {
        scrollToBottom()
    }, [noteText, showSuggester]);

    const handleChange = (e) => {
        setNoteText(e.target.value)
        if(!e.target.value.length) {
            setMemories([])
        }
    }

    useEffect(() => {
        if (suggestions.length > 0) {
            setShowSuggester(true)
        } else {
            setShowSuggester(false)
        }
    }, [suggestions]);

    const handleInput = (e) => {
        if (e.nativeEvent.inputType === 'insertText') {
            if (e.nativeEvent.data === '!') {
                setSuggestions(props.quests)
            } else if (e.nativeEvent.data === '@') {
                setSuggestions(props.characters)
            } else if (e.nativeEvent.data === '#') {
                setSuggestions(props.locations)
            } else {
                setSuggestions([])
            }
        }
    }

    const handleSuggestion = (suggestion) => {
        setSuggestions([])
        setMemories(prevState => [...new Set([...prevState, suggestion])])
        // TODO need to add it at the cursor location, not at the end
        setNoteText(prevState => prevState.slice(0, -1) + suggestion.name + ' ')
    }

    let suggester = <React.Fragment/>
    if (showSuggester) {
        suggester = <Suggester suggestions={suggestions} onSuggestionSelected={handleSuggestion}/>
    }
    const removeMemory = (m) => {
        setMemories(prevState => prevState.filter(value => value.id !== m))
    }
    let memoryList = <React.Fragment/>
    if (memories.length) {
        memoryList = memories.map(memory =>
            <MemoryChip key={memory.id} memory={memory} onDelete={removeMemory}/>
        )
    } else {
        memoryList = <span><i>Use <b>!</b>, <b>@</b> and <b>#</b> to create references to existing memories</i></span>
    }

    return (
        <form onSubmit={handleSubmit}>
            {suggester}
            <TextField
                label="Take a note..."
                value={noteText}
                onInput={handleInput}
                onChange={handleChange}
                fullWidth
                autoComplete="false"
                autoFocus={true}
                placeholder={randomBlurb()}
                variant="outlined"
                InputProps={{
                    endAdornment:
                        <React.Fragment>
                            <IconButton type="submit" color="primary"><SendIcon/></IconButton>
                        </React.Fragment>
                }}
            />
            <div style={{float: "left", clear: "both"}} ref={scrollRef}/>
            {memoryList}
        </form>
    )
}

export default NoteInput
