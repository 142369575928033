import React from 'react'
import Note from "./Note";
import {Divider, List, ListItem, ListItemText} from "@material-ui/core";

function NoteList(props) {
    return (
        <List>
            <Divider/>
            {
                props.notes.map(note =>
                    <React.Fragment key={note.id}>
                        <ListItem>
                            <ListItemText><Note onNewReference={props.onNewReference} note={note}/></ListItemText>
                        </ListItem>
                        <Divider/>
                    </React.Fragment>
                )
            }
        </List>
    );
}

export default NoteList
