import React, {useEffect, useState} from "react";
import {campaignsApi} from "../api/CampaignService";
import CampaignCard from "../components/CampaignCard";
import {GridList, GridListTile, IconButton, TextField} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {useHistory} from "react-router-dom";

function CampaignSelection() {

    const [newCampaignName, setNewCampaignName] = useState("")
    const [campaigns, setCampaigns] = useState([])
    let history = useHistory();

    useEffect(() => {
        campaignsApi.getCampaigns()
            .then((data) => {
                setCampaigns(data)
                if (campaignsApi.getSelectedCampaign() && !data.some(campaign => campaign.id === campaignsApi.getSelectedCampaignId())) {
                    campaignsApi.clearSelectedCampaign()
                }
            })
    }, []);

    const selectCampaign = (campaign) => {
        campaignsApi.setSelectedCampaign(campaign)
    }

    const handleCampaignNameChange = (e) => {
        setNewCampaignName(e.target.value)
    }

    const handleNewCampaign = (e) => {
        e.preventDefault()
        if (newCampaignName) {
            campaignsApi.createCampaign(newCampaignName)
                .then((campaign) => {
                    selectCampaign(campaign)
                    history.push('/notes')
                })
        }
    }

    const campaignList = (
        <GridList cols={3}>
            {campaigns.map(campaign =>
                <GridListTile key={campaign.id}>
                    <CampaignCard campaign={campaign} onSelectCampaign={selectCampaign}/>
                </GridListTile>
            )}
        </GridList>
    )

    return (
        <React.Fragment>
            <h1>Select a Campaign</h1>
            {campaignList}
            <form onSubmit={handleNewCampaign}>
                <TextField placeholder="Create a new campaign"
                           value={newCampaignName}
                           onChange={handleCampaignNameChange}
                           InputProps={{
                               endAdornment:
                                   <React.Fragment>
                                       <IconButton type="submit" color="primary"><AddIcon/></IconButton>
                                   </React.Fragment>
                           }}
                />
            </form>
        </React.Fragment>
    )
}

export default CampaignSelection
