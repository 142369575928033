import React from 'react'
import {Redirect, Route} from "react-router-dom";
import {campaignsApi} from "../api/CampaignService";
import {useAuth0} from "@auth0/auth0-react";

function PrivateRoute(props) {

    const {requiresCampaign, children, location, ...rest} = props
    const {isAuthenticated} = useAuth0()

    if (isAuthenticated) {
        if (requiresCampaign && !campaignsApi.getSelectedCampaign()) {
            console.log("redirect to campaigns")
            return (
                <Redirect to={{pathname: "/campaigns", state: {referer: location}}}/>
            );
        } else {
            return <Route {...rest}>{children}</Route>;
        }
    } else {
        console.log("redirect to home")
        return (
            <Redirect to={{pathname: "/", state: {referer: location}}}/>
        );
    }
}

export default PrivateRoute
