import React, {useState} from "react";

export function useKeyPress(targetKey) {
    const [keyPressed, setKeyPressed] = useState(false);

    function downHandler(e) {
        if (e.key === targetKey) {
            e.preventDefault()
            setKeyPressed(true);
        }
    }

    const upHandler = (e) => {
        if (e.key === targetKey) {
            e.preventDefault()
            setKeyPressed(false);
        }
    };

    React.useEffect(() => {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);

        return () => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        };
    });

    return keyPressed;
}
