import React, {useState} from "react";
import {
    AppBar,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Toolbar,
    Typography
} from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import NotesIcon from '@material-ui/icons/Notes';
import {Link} from "react-router-dom";
import AccessibleEmoji from "../components/AccessibleEmoji";
import AccountNav from "./AccountNav";
import ExploreIcon from '@material-ui/icons/Explore';
import FilterDramaIcon from '@material-ui/icons/FilterDrama';
import {useAuth0} from "@auth0/auth0-react";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    toolbar: theme.mixins.toolbar,
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }
}));

function NavigationBar() {

    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false)
    const {isAuthenticated} = useAuth0();

    const links = [
        {
            text: "Notes",
            dest: "/notes",
            icon: <NotesIcon/>,
            requiresLogin: true
        },
        {
            text: "Campaigns",
            dest: "/campaigns",
            icon: <MenuBookIcon/>,
            requiresLogin: true
        },
        {
            text: "Memories",
            dest: "/memories",
            icon: <FilterDramaIcon/>,
            requiresLogin: true
        },
        {
            text: "Roadmap",
            dest: "/roadmap",
            icon: <ExploreIcon/>,
            requiresLogin: false
        }
    ]

    return (
        <div className={classes.root}>
            <AppBar position="fixed">
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
                                onClick={() => setDrawerOpen(true)}>
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        <AccessibleEmoji emoji="🎲" description="dice"/> Dungeon Notekeeper
                    </Typography>
                    <AccountNav/>
                </Toolbar>
            </AppBar>
            <Drawer open={drawerOpen}
                    onClose={() => setDrawerOpen(false)}
                    className={classes.drawer}>
                <div className={classes.drawerHeader}>
                    <IconButton onClick={() => setDrawerOpen(false)}>
                        Close
                    </IconButton>
                </div>
                <Divider/>
                <List>{links.map(createListItem)}</List>
            </Drawer>
            <div className={classes.toolbar}/>
        </div>
    )

    function createListItem(link) {
        if (!link.requiresLogin || isAuthenticated) {
            return <ListItem component={Link} to={link.dest} button key={link.dest}
                             onClick={() => setDrawerOpen(false)}>
                <ListItemIcon>{link.icon}</ListItemIcon>
                <ListItemText>{link.text}</ListItemText>
            </ListItem>
        }
    }
}

export default NavigationBar
