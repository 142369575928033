import React, {useEffect, useState} from "react";
import NoteInput from "../components/NoteInput";
import NoteList from "../components/NoteList";
import {notesService} from "../api/NotesService";
import {referenceService} from "../api/ReferenceService";

function SessionNotes() {

    const [notes, setNotes] = useState([]);
    const [characters, setCharacters] = useState([])
    const [locations, setLocations] = useState([])
    const [quests, setQuests] = useState([])

    function loadReferences() {
        referenceService.getMemoriesByType('CHARACTER')
            .then((data) => setCharacters(data))
        referenceService.getMemoriesByType('QUEST')
            .then((data) => setQuests(data))
        referenceService.getMemoriesByType('LOCATION')
            .then((data) => setLocations(data))
    }

    useEffect(() => {
        notesService.getNotes()
            .then((data) => setNotes(data))
        loadReferences();
    }, []);

    const handleNewNote = (note) => {
        setNotes([...notes, note])
    }

    const handleNewReference = () => {
        loadReferences();
    }

    return (
        <React.Fragment>
            <h1>Take your notes...</h1>
            <NoteList onNewReference={handleNewReference} notes={notes}/>
            <NoteInput onNewNote={handleNewNote} characters={characters} quests={quests} locations={locations}/>
        </React.Fragment>
    )
}

export default SessionNotes
