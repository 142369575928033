import {getApiToken} from "./Auth0Client";

const BASE_PATH = process.env.REACT_APP_DUNGEON_NOTEKEEPER_BASE_URI

export const request = async (method, path, opts = {}) => {
    let url = new URL(`${BASE_PATH}/api/v1${path}`);

    const token = await getApiToken()

    const request = {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }

    if (opts.body) {
        request.body = JSON.stringify(opts.body)
    }

    if (opts.params) {
        url.search = new URLSearchParams(opts.params).toString()
    }

    return fetch(url, request)
        .then(function (response) {
            return response.text().then(text => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }
                return data;
            });
        });
}

export const postRequest = async (method, path, body) => {
    const token = await getApiToken()

    return fetch(`${BASE_PATH}/api/v1${path}`, {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body),
    }).then(function (response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }

            return data;
        });
    });
}
