import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import NoteList from "../components/NoteList";
import {notesService} from "../api/NotesService";
import {referenceService} from "../api/ReferenceService";

function MemoryNotes(props) {

    const {memoryType, slug} = useParams()
    const [notes, setNotes] = useState([]);
    const [memory, setMemory] = useState({});

    useEffect(() => {
        notesService.getMemoryNotes(memoryType, slug)
            .then((data) => setNotes(data))

        referenceService.getMemory(memoryType, slug)
            .then((data) => setMemory(data))
    }, [memoryType, slug]);

    return (
        <React.Fragment>
            <h1>{memory.name}</h1>
            <NoteList notes={notes}/>
        </React.Fragment>
    )
}

export default MemoryNotes