import React, {useEffect, useState} from "react";
import {referenceService} from "../api/ReferenceService";
import {List, ListItem} from "@material-ui/core";
import MemoryLink from "../components/MemoryLink";

function Memories() {

    const [memories, setMemories] = useState([]);

    useEffect(() => {
        referenceService.getMemories()
            .then((data) => setMemories(data))
    }, []);

    return (
        <React.Fragment>
            <h1>Memories</h1>
            <List>
                {
                    memories.map(memory => {
                        return <ListItem key={memory.id}>
                            <MemoryLink type={memory.type} name={memory.name} slug={memory.slug}/>
                        </ListItem>
                    })
                }
            </List>
        </React.Fragment>
    )
}

export default Memories