import {campaignsApi} from "./CampaignService";
import {request} from "./Api";

export const notesService = {
    createNote,
    getNotes,
    getMemoryNotes,
    createReference
};

async function createNote(noteText, memories) {
    let memoryKeys = memories.map(memory => {
        return {
            type: memory.type,
            slug: memory.slug
        }
    })
    return request(
        'POST',
        `/campaign/${campaignsApi.getSelectedCampaignId()}/notes`,
        {body: {noteText: noteText, memories: memoryKeys}}
    )
}

function getNotes() {
    return request('GET', `/campaign/${campaignsApi.getSelectedCampaignId()}/notes`)
}

async function getMemoryNotes(memoryType, slug) {
    return request(
        'GET',
        `/campaign/${campaignsApi.getSelectedCampaignId()}/memories/${memoryType}/${slug}/notes`
    )
}

async function createReference(noteId, referenceName, referenceType) {
    return request('PUT',
        `/notes/${noteId}/memories`,
        {body: {name: referenceName, memoryType: referenceType}}
    )
}
